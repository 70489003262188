/**
 * 1ケタの数字を2ケタにする
 * @param digit
 */
import { format, getDay, Locale } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { HardLimitTime } from '~/framework/constants';
import { Maybe, Seconds } from '~/framework/typeAliases';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';

/**
 * 人間が読める年月日時分のフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const dateToYymmddhhmm = (date: Date, locale: Locale = ja): string => {
  return format(date, 'yyyy/MM/dd HH:mm', { locale });
};

/**
 * YYYY/MM/DD(曜日)のフォーマットに変換する
 * @param date
 * @param locale = ja
 */
export const dateToYyyymmddDay = (date: Date, locale: Locale = ja): string => {
  const dayOfWeek = daysOfWeekMap[getDay(date)];
  return `${format(date, 'yyyy/MM/dd', { locale })}(${dayOfWeek})`;
};

/**
 * 2つの日付が同一日であるかどうかを返す
 * @param date1
 * @param date2
 */
export const isEqualDate = (date1: Date, date2: Date): boolean => {
  return dateToYyyymmddDay(date1) === dateToYyyymmddDay(date2);
};

export const twoDigits = (digit: number): string => {
  return `${digit < 10 ? '0' : ''}${digit}`;
};

/**
 * 00:00 からの秒数を HH:MM のフォーマットに直す
 * @param secs
 */
export const secsToHhMm = (secs: number): string => {
  if (secs === undefined) return 'undefined';
  const [hours, minutes] = getHoursAndMinutesOf(secs);
  return `${twoDigits(hours)}:${twoDigits(minutes)}`;
};

/**
 * HH:MM のフォーマットを 00:00 からの秒数に直す
 * form input などフォーマットが正しくない入力がくる可能性がある場合は throw してしまうので注意
 * @param hhmm
 */
export const hhMmToSecs = (hhmm: string): number => {
  if (hhmm === undefined) return -1;
  const matches = getHhMmRegexMatches(hhmm);
  if (matches.length < 1) {
    throw new Error(`${hhmm} is not in the format of HH:MM`);
  }
  return Number.parseInt(matches[0][1]) * 60 * 60 + Number.parseInt(matches[0][2]) * 60;
};

/**
 * secs を与えると 11…12 の様な曖昧な表現にして返す
 * @param secs 00:00 からの秒数
 */
export const unstableTime = (secs: number): string => {
  const hours = Math.floor(secs / (60 * 60));
  const mins = Math.floor(secs % (60 * 60)) / 60;
  if (mins <= 30) {
    let prevHour = hours - 1;
    if (prevHour < 0) prevHour += 24;
    return `${twoDigits(prevHour)}…${twoDigits(hours)}`;
  } else {
    let nextHour = hours + 1;
    if (24 <= nextHour) nextHour -= 24;
    return `${twoDigits(hours)}…${twoDigits(nextHour)}`;
  }
};

/**
 * 9:30 とかの文字列を ['9', '30'] のタプルに変換する
 * form input などフォーマットが正しくない入力がくる可能性がある場合は throw してしまうので注意
 * @param hhmm
 */
export const splitHhMm = (hhmm: string): [string, string] => {
  if (hhmm === undefined) return ['', ''];
  const matches = getHhMmRegexMatches(hhmm);
  if (matches.length < 1) {
    throw new Error(`${hhmm} is not in the format of HH:MM`);
  }
  return [matches[0][1], matches[0][2]];
};

/**
 * 秒数から xx時間yy分 の表記に変換する
 *
 * - 時がなければ省略
 * - 0分の場合は分は省略
 *
 * @param secs
 * @param useUnderPoint
 */
export const secsToDuration = (secs: number): string => {
  const [hours, minutes] = getHoursAndMinutesOf(secs);
  if (hours === 0 && minutes === 0) return `0分`;
  if (0 < hours && minutes === 0) return `${hours}時間`;
  if (hours === 0 && 0 < minutes) return `${minutes}分`;
  else return `${hours}時間${minutes}分`;
};

/**
 * 秒数から yy.zz分 の表記に変換する
 * 少数点以下は2桁
 *
 * - 小数点以下を扱う場合には、useUnderPointを利用する
 *
 * @param secs
 * @param useUnderPoint
 */
export const secsToDurationInMins = (secs: number, useUnderPoint: boolean = false): string => {
  const minutes = Math.floor(secs / 60);
  const withDecimalPoint = Math.round((secs * 100) / 60) / 100;

  if (secs === 0 || (!useUnderPoint && minutes === 0)) return `0分`;
  return `${useUnderPoint ? withDecimalPoint : minutes}分`;
};

/**
 * 秒数から分数を取得する
 *
 * @param secs
 */
export const secsToMinutes = (seconds: number): number => {
  const minutes = Math.floor(seconds / 60);
  return minutes;
};

/**
 * 秒数から時間を取得する
 * @param secs
 */
export const getHoursOf = (secs: number): number => {
  const hours = Math.floor(secs / (60 * 60));
  return hours;
};

/**
 * 秒数から分間を取得する
 * @param secs
 */
export const getMinutesOf = (secs: number): number => {
  const minutes = Math.floor((secs % (60 * 60)) / 60);
  return minutes;
};

/**
 * 秒数から時間と分間を取得する
 * @param secs
 */
export const getHoursAndMinutesOf = (secs: number): [number, number] => {
  const hours = Math.floor(secs / (60 * 60));
  const minutes = Math.floor((secs % (60 * 60)) / 60);
  return [hours, minutes];
};

/**
 * 時間から秒数を取得する
 * @param hours
 */
export const getSecondsFromHours = (hours: number): number => {
  const seconds = hours * 60 * 60;
  return seconds;
};

/**
 * 分間から秒数を取得する
 * @param minutes
 */
export const getSecondsFromMinutes = (minutes: number): number => {
  const seconds = minutes * 60;
  return seconds;
};

/**
 * xx:xx〜xx:xx の様な文字列を返す
 * @param start
 * @param end
 */
export const formatPeriod = (start: Maybe<Seconds>, end: Maybe<Seconds>): string => {
  if (start === undefined && end === undefined) return '';
  if (start === end) return secsToHhMm(start!);
  const startHhmm = start !== undefined ? secsToHhMm(start) : '';
  const endHhmm = end !== undefined ? secsToHhMm(end) : '';
  return `${startHhmm}〜${endHhmm}`;
};

/**
 * HH:MM のフォーマットをチェックする
 * HH:MMのフォーマットの場合、true
 * @param hhmm
 */
export const isValidFormatHhMm = (hhmm: string): boolean => {
  const matches = getHhMmRegexMatches(hhmm);
  return matches.length === 1;
};

/**
 * HH:MMを [HH,MM]の配列で返す
 * @param hhmm
 */
const getHhMmRegexMatches = (hhmm: string): RegExpMatchArray[] => {
  const regex = /^([0-9]{1,2}):([0-9]{1,2})$/g;
  return Array.from(hhmm.matchAll(regex));
};

/**
 * 翌日かどうかを判定する
 * startTime > endTime なら翌日と判定する
 * @param startTime
 * @param endTime
 */
export const isNextDay = (startTime: number, endTime: number): boolean => {
  return startTime > endTime;
};

/**
 * ハードリミットの時間を超えていないかを判定する
 * @param time
 */
export const validateHardLimitTime = (time: number): boolean => {
  if (time >= HardLimitTime) {
    return false;
  }
  return true;
};

/**
 * startTime と endTime の間に time があるかどうかを判定する
 * @param time
 * @param start
 * @param end
 */
export const isInTimeRange = (time: number, start: number, end: number): boolean => {
  if (time >= start && time <= end) {
    return true;
  }
  return false;
};

/**
 * 文字列を HH:MM であると確定したものを取得する
 * 全角数字、コロンのあるなしに対応する
 * コロンなしの場合、3桁もしくは4桁の入力に対応する
 */
export function strToHHMMorBlank(str: string): string {
  function isValidHh(hh: string): boolean {
    if (hh === '') return false;
    const h = Number(hh);
    return h >= 0 && h < getHoursOf(HardLimitTime);
  }
  function isValidMm(mm: string): boolean {
    if (mm === '') return false;
    const m = Number(mm);
    return m >= 0 && m < 60;
  }
  /**
   * 数字とコロン（全角半角変換あり）
   *
   * @param {String} str 変換したい文字列
   **/
  function toHalfWidth(str: string): string {
    return str
      .replace(/[０-９：]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/[^\d:]/g, '');
  }

  const halfStr = toHalfWidth(str) + '';
  let hh = '';
  let mm = '';
  // : 付き入力
  if (halfStr.includes(':')) {
    [hh, mm] = halfStr.split(':');
  } else {
    // : ついてない入力は文字数で振り分け
    if (halfStr.length === 3) {
      hh = halfStr.slice(0, 1);
      mm = halfStr.slice(1);
    }
    if (halfStr.length === 4) {
      hh = halfStr.slice(0, 2);
      mm = halfStr.slice(2);
    }
  }

  // 時間の採りうる範囲 0〜35/ 0~59 ならHH:MM そうでなければ空文字を返す
  if (isValidHh(hh) && isValidMm(mm)) return `${hh}:${mm}`;
  return '';
}

export function getTimeRangeName(hh: number): string {
  if (hh < 12) return '午前';
  if (hh < 24) return '午後';
  return '翌';
}
